<template>
  <div id="cities-slider-container">
    <DefineCityCard v-slot="{ image, imageSet, imageAlt, location, to }">
      <NuxtLink
        class="relative flex flex-col w-[225px] h-[295px] px-3 py-8 mb-2 rounded-lg overflow-hidden shadow-lg"
        :to="to"
      >
        <img
          v-if="image"
          :src="image"
          :srcset="imageSet"
          loading="lazy"
          :alt="imageAlt"
          class="absolute z-[-1] left-0 top-0 w-full h-full object-cover object-center"
          width="225"
          height="295"
        />
        <div class="pl-2 text-xl text-white text-left font-bold drop-shadow-lg">
          {{ location }}
        </div>
      </NuxtLink>
    </DefineCityCard>
    <Splide
      :options="{
        focus: false,
        perMove: 3,
        drag: 'free',
        fixedWidth: '225px',
        gap: '16px',
        pagination: false,
        trimSpace: 'move',
        omitEnd: true,
        i18n: {
          slide: ''
        },
        breakpoints: {
          640: {
            arrows: false
          }
        }
      }"
      class="splide-center mb-6"
    >
      <SplideSlide v-for="(item, index) in items" :key="index">
        <CityCard v-bind="item"></CityCard>
      </SplideSlide>
    </Splide>
  </div>
</template>

<script setup lang="ts">
import { Splide, SplideSlide } from '@splidejs/vue-splide';
import '@splidejs/vue-splide/css/core';
import { createReusableTemplate } from '@vueuse/core';

import { fixImageUrl } from '@/helpers/fixes';

interface City {
  image: string;
  imageAlt: string;
  imageSet: string;
  location: string;
  to: string;
}

interface CityFromResponse {
  title: string;
  featuredImage: {
    node: {
      sourceUrl: string;
      altText: string;
    };
  };
  relations: {
    county: {
      title: string;
    };
  };
}

const [DefineCityCard, CityCard] = createReusableTemplate<City>();

const {
  public: { cloudflareImageProcessing: cfUrl }
} = useRuntimeConfig();

const localePath = useLocalePath();

const { valueToParam } = useSearch();

const { t } = useI18n();

function tl(key: string, locale: string = 'us') {
  return t(key, null, { locale });
}

function searchPath(county: string, city: string) {
  return localePath(
    `/${tl('routes.properties')}/${t('paramsAliases.RES')}` +
      (county ? `/${valueToParam(county)}-${t('paramsKeys.county')}` : '') +
      `/${valueToParam(city)}-${t('paramsKeys.city')}`
  );
}

const {
  public: { apiUrl }
} = useRuntimeConfig();

const { get } = useHttpMethods();

async function fetch() {
  const response = await get<{
    locationCarousel: { carousel: { locations: CityFromResponse[] } };
  }>(`${apiUrl}/locations/carousel`);
  return response?.locationCarousel?.carousel.locations.map(
    ({ title, featuredImage, relations }) => {
      const county = relations?.county?.title?.replace(/( County)$/i, '');

      return {
        location: title,
        image: featuredImage.node.sourceUrl,
        imageAlt: featuredImage.node.altText || t('common.photoOf', [title]),
        to: searchPath(county, title)
      };
    }
  );
}

const { data: asyncItems, error } = await useAsyncData<City[]>(async () => {
  if (process.client) {
    return [];
  }
  return await fetch();
});

const clientItems = ref<City[]>([]);

onMounted(() => {
  if (!asyncItems.value?.length) {
    fetch().then(response => (clientItems.value = response));
  }
});

const items = computed(() => {
  const items = asyncItems.value?.length ? asyncItems.value : clientItems.value;

  return items.map(item => {
    const fixedImageUrl = fixImageUrl(item.image);
    const image = fixedImageUrl
      ? `${cfUrl}/image?w=225&h=295&f=webp&fit=crop&image=${fixedImageUrl}`
      : '';
    const imageSet = fixedImageUrl
      ? `${image} 1x, ${cfUrl}/image?w=450&h=590&f=webp&fit=crop&image=${fixedImageUrl} 2x`
      : '';
    return {
      ...item,
      image,
      imageSet
    };
  });
});
</script>

<style lang="scss">
@import '~/assets/css/splide-theme.scss';
</style>
